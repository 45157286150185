import { JSEncrypt } from 'jsencrypt'
const publicKey =
  'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDMOcPB06u5yKyQsPjfVWiWgbEIrd14kiXNNihciaVKb6HnkQvq7zpQuZ80WEX94spnUMI3iOAl/GmIvHrpGwcbB4hJbznm+PajiwnUSPuCCXA68YJF640cJKb/8KeM7WVz69OFkIEPHhVxOy4FFF5QWe/kt6zOZ19HmE+ak+5x/QIDAQAB'

// 加密方法
export function RSAencrypt(pas) {
  // 实例化jsEncrypt对象
  const jse = new JSEncrypt()
  // 设置公钥
  jse.setPublicKey(publicKey)
  // 加密后的密码
  const res = jse.encrypt(pas)
  // console.log('加密：' + res)
  return res
}
