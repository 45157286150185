import request from '@/utils/request'

// 获取账号管理列表
export const getUserList = (params) => {
  return request({
    url: '/user/list',
    method: 'get',
    params
  })
}

// 新增账号
export const addUserList = (data) => {
  return request({
    url: '/user/add',
    method: 'post',
    data
  })
}

// 编辑账号
export const editUserList = (data) => {
  return request({
    url: '/user/update',
    method: 'put',
    data
  })
}

// 修改当前用户密码
export const editPassword = (data) => {
  return request({
    url: '/user/updatePassword',
    method: 'put',
    data
  })
}

// 删除管理员
export const deleteUserList = (data) => {
  return request({
    url: '/user/delete',
    method: 'delete',
    data
  })
}
