import { createRouter, createWebHashHistory } from 'vue-router'
// createWebHistory,
import layout from '@/layout'

// 公开路由表
const publicRoutes = [
  {
    path: '/login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/login/index.vue')
  },
  {
    path: '/',
    redirect: '/projectManagement',
    component: layout,
    children: [
      {
        path: '/projectManagement',
        name: 'projectManagement',
        component: () =>
          import(
            /* webpackChunkName: "projectManagement" */ '@/views/project-management/index'
          ),
        meta: {
          title: '项目管理',
          icon: 'Tickets',
          adminType: 2
        }
      },
      {
        path: '/accountManagement',
        name: 'accountManagement',
        component: () =>
          import(
            /* webpackChunkName: "accountManagement" */ '@/views/account-management/index'
          ),
        meta: {
          title: '账号管理',
          icon: 'Avatar',
          adminType: 1
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  // history:
  //   process.env.NODE_ENV === 'production'
  //     ? createWebHistory()
  //     : createWebHashHistory(),
  routes: [...publicRoutes]
})

export default router
