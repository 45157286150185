import { login } from '@/api/sys'
import { setItem, getItem, removeAllItem } from '@/utils/storage'
import { TOKEN, USERINFO } from '@/constant/index'
import router from '@/router/index'
import { RSAencrypt } from '@/utils/jsencrypt'
import { setTimeStamp } from '@/utils/auth'

export default {
  namespaced: true,
  state: () => ({
    token: getItem(TOKEN) || '',
    userInfo: getItem(USERINFO) || {
      name: '',
      adminType: '',
      id: ''
    }
  }),
  mutations: {
    setToken(state, token) {
      state.token = token
      setItem(TOKEN, token)
    },
    setInfo(state, data) {
      state.userInfo.name = data.name
      state.userInfo.adminType = data.adminType
      state.userInfo.id = data.id
      setItem(USERINFO, state.userInfo)
    }
  },
  actions: {
    // 登录请求
    login(context, userInfo) {
      const { userName, password } = userInfo
      return new Promise((resolve, reject) => {
        login({
          userName,
          password: RSAencrypt(password)
        })
          .then((data) => {
            this.commit('user/setToken', data.token)
            this.commit('user/setInfo', data)
            router.push('/')
            // // 保存登录时间
            setTimeStamp()
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    // 退出登录
    logout() {
      this.commit('user/setToken', '')
      this.commit('user/setInfo', {})
      removeAllItem()
      router.push('/login')
    }
  }
}
