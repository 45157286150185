<template>
  <!-- 一级 menu 菜单 -->
  <el-menu
    background-color="#1E7DFB"
    text-color="#fff"
    active-text-color="#000000"
    :default-active="activeMenu"
    unique-opened
    router
  >
    <template v-for="item in routes" :key="item.path">
      <sidebar-item
        :route="item"
        v-if="item.meta.adminType >= $store.getters.userInfo.adminType"
      ></sidebar-item>
    </template>
  </el-menu>
</template>

<script setup>
import { computed } from 'vue'
import SidebarItem from './SidebarItem'
import { useRouter, useRoute } from 'vue-router'
import { filterRouters, generateMenus } from '@/utils/route'

// // 计算路由表结构
const router = useRouter()
const routes = computed(() => {
  const filterRoutes = filterRouters(router.getRoutes())
  return generateMenus(filterRoutes)
})

// // 计算高亮 menu 的方法
const route = useRoute()
const activeMenu = computed(() => {
  const { meta, path } = route
  if (meta.activeMenu) {
    return meta.activeMenu
  }
  return path
})
</script>

<style lang="scss" scoped>
::v-deep .is-active {
  background-color: #fff;
}
</style>
