<template>
  <div class="navbar">
    <!-- 左上角区域 -->
    <div class="navbar-title">合作伙伴商机报备系统</div>
    <!-- 右上角区域 -->
    <div class="right-menu">
      <!-- 头像 -->
      <el-dropdown class="avatar-container" trigger="click">
        <div class="avatar-wrapper">
          <el-avatar>{{ $store.getters.userInfo.name }}</el-avatar>
          {{ $store.getters.userInfo.name }}
        </div>
        <template #dropdown>
          <el-dropdown-menu class="user-dropdown">
            <el-dropdown-item @click="handleEditPassword">
              <el-icon><Lock /></el-icon>
              修改密码
            </el-dropdown-item>
            <el-dropdown-item divided @click="logout">
              <el-icon><SwitchButton /></el-icon>
              退出登录
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>

    <!-- 修改密码弹窗区域 -->
    <el-dialog
      v-model="dialogVisible"
      title="修改密码"
      width="25%"
      top="20vh"
      :close-on-click-modal="false"
    >
      <div class="dialog_box">
        <div class="dialog_item">
          <span>原密码:</span>
          <el-input
            type="password"
            :show-password="true"
            style="width: 180px"
            placeholder="请输入原密码"
            v-model="password"
          ></el-input>
        </div>
        <div class="dialog_item">
          <span>新密码:</span>
          <el-input
            type="password"
            :show-password="true"
            style="width: 180px"
            placeholder="请输入新密码"
            v-model="newPassword"
          ></el-input>
        </div>
        <div class="dialog_item">
          <span>确认密码:</span>
          <el-input
            type="password"
            :show-password="true"
            style="width: 180px"
            placeholder="请再次输入新密码"
            v-model="newPassword2"
          ></el-input>
        </div>
      </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="handleEditPasswordConfirm">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { ElMessage, ElMessageBox } from 'element-plus'
import { RSAencrypt } from '@/utils/jsencrypt'
import { editPassword } from '@/api/user'

// 修改密码弹窗显示隐藏控制
const dialogVisible = ref(false)
// 修改密码弹窗数据
const password = ref('')
const newPassword = ref('')
const newPassword2 = ref('')
// 仓库
const store = useStore()

// 退出登录按钮回调
const logout = () => {
  store.dispatch('user/logout')
}

// 修改密码按钮回调
const handleEditPassword = () => {
  dialogVisible.value = true
}

// 修改密码弹窗确定按钮回调
const handleEditPasswordConfirm = () => {
  if (password.value === '') {
    ElMessage.error('请输入原密码')
    return
  }
  if (newPassword.value === '') {
    ElMessage.error('请输入新密码')
    return
  }
  if (newPassword2.value === '') {
    ElMessage.error('请再次输入新密码')
    return
  }
  if (newPassword.value !== newPassword2.value) {
    ElMessage.error('两次输入的密码不一致')
    return
  }
  ElMessageBox.confirm('确定修改密码吗？', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(async () => {
      const res = await editPassword({
        id: store.getters.userInfo.id,
        password: RSAencrypt(password.value),
        newPassword: RSAencrypt(newPassword.value)
      })
      // console.log(res)
      if (res == null) {
        dialogVisible.value = false
        ElMessage.success('修改成功')
        store.dispatch('user/logout')
      }
    })
    .catch(() => {
      ElMessage.info('已取消')
    })
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 80px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  .navbar-title {
    float: left;
    margin-left: 23px;
    line-height: 80px;
    font-size: 30px;
    font-weight: bold;
  }

  .right-menu {
    float: right;
    padding-right: 46px;

    :deep .avatar-container {
      line-height: 80px;
      cursor: pointer;
      .avatar-wrapper {
        position: relative;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        .el-avatar {
          --el-avatar-background-color: none;
          margin-right: 12px;
        }
      }
    }
  }

  .dialog_box {
    height: 150px;
    .dialog_item {
      height: 30px;
      margin-bottom: 20px;
      span {
        display: inline-block;
        width: 70px;
      }
    }
  }
}

::v-deep .el-dialog__header {
  margin: 0;
  font-weight: bold;
  background-color: #edf1f5;
}

::v-deep .el-button--primary {
  background-color: #0061ff;
}
</style>
