import { TIME_STAMP, TOKEN_TIMEOUT_VALUE } from '@/constant'
import { setItem, getItem } from '@/utils/storage'

// 获取本地缓存时间戳
export function getTimeStamp() {
  return getItem(TIME_STAMP)
}

// 保存当前登录时间戳
export function setTimeStamp() {
  setItem(TIME_STAMP, Date.now())
}

// 是否超时 true为超时
export function isCheckTimeout() {
  // 获取当前时间戳
  const currentTime = Date.now()
  // 获取缓存时间戳
  const timeStamp = getTimeStamp()
  return currentTime - timeStamp > TOKEN_TIMEOUT_VALUE
}
