import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 导入权限控制模块
import './permission'

// 引入初始化样式
import '@/styles/index.scss'

// 引入vue3 element 组件 和样式
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

// 引入vue3 element Icon图标
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)

// 全局注册element Icon图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app
  .use(store)
  .use(router)
  .use(ElementPlus, {
    locale: zhCn
  })
  .mount('#app')
