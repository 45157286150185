// token
export const TOKEN = 'agentToken'

// 用户信息
export const USERINFO = 'userInfo'

// token 时间戳
export const TIME_STAMP = 'timeStamp'

// 超时时长(毫秒) 两小时
export const TOKEN_TIMEOUT_VALUE = 2 * 3600 * 1000
